














































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import EyeIcon from '@/app/ui/assets/eye_filled.vue'
import FileIcon from '@/app/ui/assets/icon_file.vue'
import DownloadIcon from '@/app/ui/assets/icon_download.vue'
import ImageViewer from '@/app/ui/components/ImageViewer/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import ModalNotes from '../components/Modals/ModalNotes/index.vue'
import ModalUploadBulky from '../components/Modals/ModalUploadBulky/index.vue'
import ModalSuccess from '../components/Modals/ModalSuccess/index.vue'
import ModalFailed from '../components/Modals/ModalFailed/index.vue'
import controller from '@/app/ui/controllers/ManualAdjustmentBalanceController'
import {
  ManualAdjustmentBalance,
  ManualAdjustmentBalanceCourier,
  ManualAdjustmentUploadHistory,
} from '@/domain/entities/ManualAdjustmentBalance'
import { Utils } from '@/app/infrastructures/misc'
import IllustrationCreateFaq from '@/app/ui/assets/ill_create_faq.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import Card from '@/app/ui/components/Card/index.vue'
import { EnumAdjustSaldoStatus } from '@/app/infrastructures/misc/Constants/adjustSaldoApproval'
import { EnumStatusUpload } from '@/app/infrastructures/misc/Constants/manualAdjustSaldo'

interface IDateRange {
  start: string
  end: string
  shortcut: string
}

interface IOptions {
  label: string
  value: string | number
}

interface IParams {
  page: number
  per_page: number
  sort: IOptions
  keyword: string
  filter: IOptions
  activity: string
  type: string
  date_range: IDateRange | null
  time_zone: string
  insert_type: IOptions
}

export interface IHeaderCell {
  title: number | string
  customStyle: IStyle
}

export interface IStyle {
  minWidth: string
  maxWidth: string
}

export interface ITableCell {
  value: string | number | Date | string[]
  customStyle: IStyle
}

@Component({
  components: {
    Button,
    TextInput,
    DropdownSelect,
    PaginationNav,
    Badge,
    EyeIcon,
    FileIcon,
    DownloadIcon,
    DateTimePicker,
    ImageViewer,
    ModalNotes,
    ModalUploadBulky,
    ModalSuccess,
    ModalFailed,
    Loading,
    Card,
    IllustrationCreateFaq,
    DataTableV2,
  },
})
export default class ManualAdjustSaldo extends Vue {
  controller = controller
  imageViewerVisible = false
  currentDate = Utils.formatDateWithIDLocale(
    new Date().toDateString(),
    'YYYY-MM-DD'
  )
  modalSuccessVisible = false
  imageUrls: Array<string> = []

  modalNotes = {
    visible: false,
    notes: '',
  }

  enumAdjustSaldoStatus = EnumAdjustSaldoStatus

  typeOptions = [
    { label: 'Rekonsiliasi Penambahan Saldo', value: 'BAL_ADD_RCC' },
    { label: 'Rekonsiliasi Pengurangan Saldo', value: 'BAL_RDC_RCC' },
  ]

  activityOptions = [
    { label: 'Tambah Saldo', value: 'ADD_BAL' },
    { label: 'Tarik Saldo', value: 'RDC_BAL' },
  ]

  filterOptions = [
    { label: 'Semua', value: '' },
    ...this.typeOptions,
    ...this.activityOptions,
  ]

  sortOptions = [
    { label: 'Terbaru', value: 'DESC' },
    { label: 'Terlama', value: 'ASC' },
  ]

  uploadTypeOptions = [
    { label: 'Semua', value: '' },
    { label: 'Satuan', value: 'SINGLE' },
    { label: 'Upload bulky', value: 'BULKY' },
  ]

  customDateShortcuts = [
    { key: 'chooseDate', label: 'Tentukan Tanggal', value: 0 },
    { key: 'last7Days', label: '7 Hari Terakhir', value: 7 },
    { key: 'last14Days', label: '14 Hari Terakhir', value: 14 },
    { key: 'last30Days', label: '30 Hari Terakhir', value: 30 },
  ]

  parameters: IParams = {
    page: 1,
    per_page: 10,
    sort: this.sortOptions[0],
    keyword: '',
    filter: this.filterOptions[0],
    activity: '',
    type: '',
    time_zone: '',
    date_range: null,
    insert_type: this.uploadTypeOptions[0],
  }

  historyParameter = {
    page: 1,
    per_page: 5,
  }

  headers = [
    this.headerCellMapper('ID Transaksi', '140px'),
    this.headerCellMapper('Tipe Upload', '160px'),
    this.headerCellMapper('Nama File', '240px'),
    this.headerCellMapper('Dikirim Oleh', '245px'),
    this.headerCellMapper('Tanggal Kirim', '240px'),
    this.headerCellMapper('Nama Driver', '240px'),
    this.headerCellMapper('Tipe', '160px'),
    this.headerCellMapper('Aktivitas', '140px'),
    this.headerCellMapper('Jumlah', '140px'),
    this.headerCellMapper('Status', '172px'),
    this.headerCellMapper('Diterima Oleh', '245px'),
    this.headerCellMapper('Tanggal Diterima', '240px'),
    this.headerCellMapper('Bukti Transaksi', '170px'),
    this.headerCellMapper('Catatan', '220px'),
  ]

  headersHistory = [
    this.headerCellMapper('Nama File', '220px'),
    this.headerCellMapper('Dikirim Oleh', '225px'),
    this.headerCellMapper('Tanggal Submit', '200px'),
    this.headerCellMapper('Diterima/Tolak Oleh', '225px'),
    this.headerCellMapper('Tanggal Terima/Tolak', '199px'),
    this.headerCellMapper('Alasan', '225px'),
    this.headerCellMapper('Status', '180px'),
    this.headerCellMapper('Aksi', '141px'),
  ]

  perPageOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  manualAdjustList: ITableCell[][] = []

  uploadManualAdjustSaldoList: ITableCell[][] = []

  created(): void {
    this.parameters.time_zone = Utils.formatDateWithIDLocale(
      new Date().toDateString(),
      'Z'
    )
    this.fetchManualAdjustmentBalance()
    this.fetchManualAdjustmentUploadHistory()
  }

  mounted() {
    this.setDatePickerStyle()
  }

  get params(): Record<string, string | number> {
    let payload: Record<string, string | number> = {
      page: this.parameters.page,
      per_page: this.parameters.per_page,
      sort: this.parameters.sort.value,
      keyword: this.parameters.keyword,
      activity:
        this.activityOptions.find(opt => opt === this.parameters.filter)
          ?.value || '',
      type:
        this.typeOptions.find(opt => opt === this.parameters.filter)?.value ||
        '',
      time_zone: encodeURIComponent(this.parameters.time_zone),
      insert_type: this.parameters.insert_type.value,
    }

    if (this.parameters.date_range?.start && this.parameters.date_range?.end) {
      payload.date_from = this.parameters.date_range?.start
      payload.date_to = this.parameters.date_range?.end
    }

    return payload
  }

  get historyParams(): Record<string, string | number> {
    return {
      page: this.historyParameter.page,
      per_page: this.historyParameter.per_page,
    }
  }

  private setDatePickerStyle(): void {
    ;(this.$el.getElementsByClassName(
      'shortcuts-container'
    )[0] as HTMLDivElement).style.minWidth = '160px'
  }

  private fetchManualAdjustmentBalance(reset?: boolean): void {
    if (reset) this.parameters.page = 1
    controller.getAll(this.params)
  }

  private fetchManualAdjustmentBalancePerPage(val: number): void {
    this.parameters.per_page = val
    this.fetchManualAdjustmentBalance(true)
  }

  private fetchManualAdjustmentUploadHistory(): void {
    controller.getListUploadHistory(this.historyParams)
  }

  private onFilterList = Utils.debounce(() => {
    this.fetchManualAdjustmentBalance(true)
  }, 400)

  private onDatepickerFilter(): void {
    if (this.parameters.date_range?.start && !this.parameters.date_range?.end) {
      return
    }

    this.onFilterList()
  }

  private handleModalNotes(visible: boolean, note: string): void {
    this.modalNotes.visible = visible
    this.modalNotes.notes = note
  }

  private onExportExcel() {
    // TEMPORARY FIX FOR EXPORT EXCEL
    window.open(
      'https://metabase.thelionparcel.com/question/1790-prd-manual-adjusment',
      '_blank'
    )

    // TODO: NEED ADJUSTMENT LATER FOR EXPORT EXCEL
    // let exportParams: Record<string, string | number> = {
    //   sort: this.params.sort,
    //   keyword: this.params.keyword,
    //   activity: this.params.activity,
    //   type: this.params.type,
    //   time_zone: this.params.time_zone
    // }
    //
    // if (this.params.date_from) {
    //   exportParams.date_from = this.params.date_from || ''
    // }
    //
    // if (this.params.date_to) {
    //   exportParams.date_to = this.params.date_to || ''
    // }
    //
    // controller.exportManualAdjustmentBalance(exportParams)
  }

  private onDownloadTemplate(): void {
    controller.downloadTemplate()
  }

  private onHandleImageViewer(visible: boolean, urls: Array<string>): void {
    if (urls) {
      if (urls.length !== 0) {
        this.imageUrls = urls.map(
          url => process.env.VUE_APP_STORAGE_BASE_URL + '/' + url
        )
      } else if (urls.length === 0) {
        this.imageUrls = []
      }
    }
    this.imageViewerVisible = visible
  }

  private formatTypeLabel(type?: string): string | undefined {
    const res = this.typeOptions.find(t => type === t.value)
    return res?.label
  }

  private formatActivityValue(activity?: string): string | undefined {
    const res = this.activityOptions.find(a => activity === a.value)
    return res?.label
  }

  private formatName(courier?: ManualAdjustmentBalanceCourier): string {
    if (courier) {
      return `[${courier?.id}] ${courier?.fullname} ${courier?.phoneNumber}`
    }
    return ''
  }

  private formatAmount(amount?: number): string | undefined {
    if (!amount) {
      return '-'
    }

    if (amount < 0) {
      return `-${Utils.toRupiah(amount * -1)}`
    } else if (amount >= 0) {
      return `${Utils.toRupiah(amount)}`
    }
  }

  private formatedStatus(status?: string): string {
    let result = '-'
    switch(status) {
      case this.enumAdjustSaldoStatus.APPROVE:
        result = 'Terima'
        break;
      case this.enumAdjustSaldoStatus.REJECT:
        result = 'Tolak'
        break;
      case this.enumAdjustSaldoStatus.WAITING_FOR_APPROVAL:
        result = 'Menunggu'
        break
    }
    return result
  }

  private coloringStatus(status: string): string {
    let result = ''
    switch (status) {
      case 'Terima':
        result = 'success'
        break
      case 'Tolak':
        result = 'error-1'
        break
      case 'Menunggu':
        result = 'warning'
        break
    }
    return result
  }
  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  private navigateToDetail(id: number): void {
    this.$router.push('/payroll/manual-adjust-saldo/detail/' + id)
  }

  @Watch('controller.dataPayrollManualAdjustmentList')
  setDataPayrollManualAdjustmentList(data: ManualAdjustmentBalance[]): void {
    const result = data.map((list: ManualAdjustmentBalance) => {
      return [
        this.tableCellMapper(list.id || '-', '140px'),
        this.tableCellMapper(list.insertType || '-', '160px'),
        this.tableCellMapper(list.filename || '-', '240px'),
        this.tableCellMapper(list.createdBy || '-', '245px'),
        this.tableCellMapper(
          list.createdAt
            ? [
                Utils.formatDateWithIDLocale(
                  list.createdAt,
                  'dddd DD MMM YYYY,'
                ),
                Utils.formatTimeZone(
                  Utils.formatDateWithIDLocale(
                    list.createdAt,
                    'HH:mm Z'
                  )
                )
              ]
            : [],
          '240px'
        ),
        this.tableCellMapper(this.formatName(list.courier) || '-', '240px'),
        this.tableCellMapper(this.formatTypeLabel(list.type) || '-', '160px'),
        this.tableCellMapper(
          this.formatActivityValue(list.activity) || '-',
          '140px'
        ),
        this.tableCellMapper(this.formatAmount(list.amount) || '-', '140px'),
        this.tableCellMapper(list.status || '-', '172px'),
        this.tableCellMapper(list.approvedBy || '-', '245px'),
        this.tableCellMapper(
          list.approvedAt
            ? [
                Utils.formatDateWithIDLocale(
                  list.approvedAt,
                  'dddd DD MMM YYYY,'
                ),
                Utils.formatTimeZone(
                  Utils.formatDateWithIDLocale(
                    list.approvedAt,
                    'HH:mm Z'
                  )
                )
              ]
            : [],
          '240px'
        ),
        this.tableCellMapper(list.meta || [], '170px'),
        this.tableCellMapper(list.notes || '-', '220px'),
      ]
    })

    this.manualAdjustList = result as ITableCell[][]
  }

  @Watch('controller.dataManualAdjustmentUploadHistoryList')
  setDataManualAdjustmentUploadHistoryList(
    data: ManualAdjustmentUploadHistory[]
  ): void {
    const result = data.map((list: ManualAdjustmentUploadHistory) => {
      return [
        this.tableCellMapper(list.filename || '-', '220px'),
        this.tableCellMapper(list.createdBy || '-', '225px'),
        this.tableCellMapper(
          list.createdAt
            ? [
                Utils.formatDateWithIDLocale(
                  list.createdAt,
                  'dddd DD MMM YYYY,'
                ),
                Utils.formatTimeZone(
                  Utils.formatDateWithIDLocale(
                    list.createdAt,
                    'HH:mm Z'
                  )
                )
              ]
            : [],
          '200px'),
        this.tableCellMapper(list.updatedBy || '-', '225px'),
        this.tableCellMapper(
          list.updatedAt
            ? [
                Utils.formatDateWithIDLocale(
                  list.updatedAt,
                  'dddd DD MMM YYYY,'
                ),
                Utils.formatTimeZone(
                  Utils.formatDateWithIDLocale(
                    list.updatedAt,
                    'HH:mm Z'
                  )
                )
              ]
            : [],
          '199px'),
        this.tableCellMapper(list.reason || '-', '225px'),
        this.tableCellMapper(this.formatedStatus(list.status), '180px'),
        this.tableCellMapper(list.filepath ? `${list.id};${list.filepath}` : '-', '141px'),
      ]
    })

    this.uploadManualAdjustSaldoList = result as ITableCell[][]
  }

  @Watch('controller.statusUpload')
  refreshManualAdjustmentList(data: string): void {
    if (data === EnumStatusUpload.COMPLETE) {
      this.fetchManualAdjustmentBalance()
    }
  }
}
